import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { BLOCKS } from '@contentful/rich-text-types'

import Layout from '../components/layout'
import Head from '../components/head'

// Ako som to urobil:
// https://stackoverflow.com/questions/65270567/fetch-image-from-contentful-richtext-field-thorugh-graphql
// co-to je aj tu: https://www.gatsbyjs.com/plugins/gatsby-plugin-image#static-images
// a tu: https://github.com/gatsbyjs/gatsby/pull/25249
// musim vyskusat aj referencie na fixed, fluid a gatsbyImageData v GraphQL


export const query = graphql`
query ($slug: String!) {
	contentfulBlogPost(slug: {eq: $slug}) {
		title
		publishDate(formatString: "MMM Do, YYYY")
		body {
			raw
			references {
				... on ContentfulAsset {
					contentful_id
					__typename
					title
					fixed(width: 200) {
						src
					}
				}
			}
		}
	}
}`

const Blog = (props) => {
	// console.log(JSON.stringify(props.data.contentfulBlogPost.body, undefined, 4))
	const options = {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: node => {
				// console.log('@@@@@@@@@', node)
				const {
					fixed: { src },
					title,
				} = node.data.target
				
				return <img alt={title} src={src} />
			}
		}
	}
	return (
		<Layout>
			<Head title={props.data.contentfulBlogPost.title} />
			<h1>{props.data.contentfulBlogPost.title}</h1>
			<p>{props.data.contentfulBlogPost.publishDate}</p>
			{renderRichText(props.data.contentfulBlogPost.body, options)}
		</Layout>
	)
}

export default Blog